/* This code was imported from the universal-login-spa's password checker.
   Source: https://dev.azure.com/colesgroup/CustomerIdentity/_git/universal-login-spa?path=%2Fsrc%2Fcommon%2Fpassword-strength-checker */

.password-strength-checker {
  display: flex;
  flex-direction: row;
  margin: 0 -3px;
  padding-right: 1%;
}

.strength-bar {
  width: 33%;
  height: 5px;
  margin-left: 3px;
  margin-top: 5px;
  transition: width 2s;
  background-color: #acacac;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.strength-bar-background {
  height: 100%;
  width: 0;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: width 1s, background-color 1s;
  -moz-transition: width 1s, background-color 1s;
  -o-transition: width 1s, background-color 1s;
  transition: width 1s, background-color 1s;
}

.strength-bar-background.weak-password {
  background-color: red;
  width: 100%;
}

.strength-bar-background.moderate-password {
  background-color: orange;
  width: 100%;
}

.strength-bar-background.strong-password {
  background-color: limegreen;
  width: 100%;
}

.strength-text {
  display: block;
  font-weight: bold;
  font-size: 13px;
  height: 24px;
  margin-bottom: 24px;
}
