html,
body,
#root,
#app > div {
  height: 100%;
}

body {
  background-color: #efeff4;
  margin: 0;
  font-family: "Source Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.rocket {
  background-color: var(
    --colors-colorBackground-primary-default,
    var(--colors-background-default, #ffffff)
  );
}

/*
  Fonts (source: https://rocketdesignsystem.github.io/system.html)
 */
@font-face {
  font-family: "Avant Garde";
  src: url("./assets/fonts/AvantGardePro/AvantGardeGothicITCW05-Demi.woff2")
      format("woff2"),
    url("./assets/fonts/AvantGardePro/AvantGardeGothicITCW05-Demi.woff")
      format("woff"),
    url("./assets/fonts/AvantGardePro/AvantGardeGothicITCW05-Demi.ttf")
      format("truetype"),
    url("./assets/fonts/AvantGardePro/AvantGardeGothicITCW05-Demi.eot?#iefix")
      format("embedded-opentype");
}

@font-face {
  font-family: "Source Sans";
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-Regular.woff2")
      format("woff2"),
    url("./assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf")
      format("truetype");
}

@font-face {
  font-family: "Source Sans";
  font-style: italic;
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-Italic.woff2")
      format("woff2"),
    url("./assets/fonts/SourceSansPro/SourceSansPro-Italic.ttf")
      format("truetype");
}

@font-face {
  font-family: "Source Sans";
  font-weight: 600;
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-SemiBold.woff2")
      format("woff2"),
    url("./assets/fonts/SourceSansPro/SourceSansPro-SemiBold.ttf")
      format("truetype");
}

/* For Rocket */
@font-face {
  font-family: "AvantGardeGothic";
  src: url("./assets/fonts/AvantGardePro/AvantGardeGothicITCW05-Demi.woff2")
      format("woff2"),
    url("./assets/fonts/AvantGardePro/AvantGardeGothicITCW05-Demi.woff")
      format("woff"),
    url("./assets/fonts/AvantGardePro/AvantGardeGothicITCW05-Demi.ttf")
      format("truetype"),
    url("./assets/fonts/AvantGardePro/AvantGardeGothicITCW05-Demi.eot?#iefix")
      format("embedded-opentype");

  font-display: swap;
}

@font-face {
  font-family: "SourceSansPro";
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-Regular.woff2")
      format("woff2"),
    url("./assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf")
      format("truetype");
}

@font-face {
  font-family: "SourceSansPro";
  font-style: italic;
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-Italic.woff2")
      format("woff2"),
    url("./assets/fonts/SourceSansPro/SourceSansPro-Italic.ttf")
      format("truetype");
}

@font-face {
  font-family: "SourceSansPro";
  font-weight: 600;
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-SemiBold.woff2")
      format("woff2"),
    url("./assets/fonts/SourceSansPro/SourceSansPro-SemiBold.ttf")
      format("truetype");
}

.ReactModal__Overlay--after-open {
  z-index: 1;
}
